<template>
  <lab-task>
    <lab-analysis-of-iron :unknowns="unknowns" />
  </lab-task>
</template>

<script>
import Vue from 'vue';
import LabAnalysisOfIron from '../simulations/LabAnalysisOfIron';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default Vue.extend({
  name: 'AnalysisOfIron',
  components: {LabAnalysisOfIron, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    unknown1Fe() {
      return this.taskState.getValueBySymbol('unknown1Fe').content.toFloat();
    },
    unknown2Fe() {
      return this.taskState.getValueBySymbol('unknown2Fe').content.toFloat();
    },
    unknown3Fe() {
      return this.taskState.getValueBySymbol('unknown3Fe').content.toFloat();
    },

    unknowns() {
      return {
        UnknownOne: {
          fe: this.unknown1Fe,
          diWater: 10 - this.unknown1Fe,
        },
        UnknownTwo: {
          fe: this.unknown2Fe,
          diWater: 10 - this.unknown2Fe,
        },
        UnknownThree: {
          fe: this.unknown3Fe,
          diWater: 10 - this.unknown3Fe,
        },
      };
    },
  },
});
</script>
