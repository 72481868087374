









import Vue, {PropOptions} from 'vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';

export interface IIronUnknownInputs {
  UnknownOne: IIronUnknownInput;
  UnknownTwo: IIronUnknownInput;
  UnknownThree: IIronUnknownInput;
}

export interface IIronUnknownInput {
  fe: number;
  diWater: number;
}

export default Vue.extend({
  name: 'LabAnalysisOfIron',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    unknowns: {
      type: Object,
      required: true,
    } as PropOptions<IIronUnknownInputs>,
  },
  computed: {
    stringifiedData(): string {
      return JSON.stringify(this.unknowns);
    },
  },
});
